import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FirestoreService} from '../../services/firestore.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {ClientInContextService} from 'src/app/services/client-in-context.service';
import {Subscription} from 'rxjs';

const moment = require('moment-timezone');

@Component({
  selector: 'app-device-last-seen',
  templateUrl: './device-last-seen.component.html',
  styleUrl: './device-last-seen.component.scss'
})
export class DeviceLastSeenComponent implements OnInit, OnDestroy {
  cards: any
  totalRows: any;
  minWidth: number;
  cardSize: number;
  iconSize: number;
  mainSize: number;
  smallSize: number;
  deviceLastSeenList: any[];
  isDataLoaded = true;
  locationName = '';
  loggedInUserFromAuthServiceSubscription: Subscription;
  clientId: string
  allDevicesSubscription: Subscription;

  constructor(private renderer: Renderer2,
    private elRef: ElementRef,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private route: ActivatedRoute,
    private contextService: ClientInContextService) {
  }

  ngOnInit(): void {
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userInfo: any) => {
        this.clientId = userInfo.clientId;
        this.firestoreService.setClientId(userInfo.clientId);
        this.cards = Array.from({length: 32});
        const key = this.route.snapshot.queryParamMap.get('key');
        const locationId = this.route.snapshot.queryParamMap.get('location_id');
        this.getDevices(key, locationId);
      });
  }

  async getDevices(castingKey, locationId) {
    if (castingKey) {
      this.isDataLoaded = false;
    }

    if (this.authService.loggedInCustomUID && this.clientId) {
      await this.setupData(locationId);
      return;
    }

    try {
      await this.authService.signInAnonymously();
    } catch (error) {
      console.log(`Error:${JSON.stringify(error)}`);
    }

    console.log(`Getting sign-in token...`);
    this.firestoreService.getSignInToken(castingKey).subscribe(async (response) => {
      try {
        this.clientId = response.clientId;
        this.firestoreService.setClientId(response.clientId);
        await this.authService.signIn(response.customToken);
        console.log(`Signed in successfully`);
        await this.setupData(locationId);
        return;
      } catch (error) {
        console.log(`Error:${JSON.stringify(error)}`);
        this.isDataLoaded = true;
      }
    });
  }


  convertLastSeenTimestamp(timeStamp: any) {
    const seconds = timeStamp.seconds;
    const nanoseconds = timeStamp.nanoseconds;
    // Convert to milliseconds
    const lastSeenMillis = (seconds * 1000) + (nanoseconds / 1e6);
    const lastSeenDate = moment(lastSeenMillis);

    // Get time difference from now
    const duration = moment.duration(moment().diff(lastSeenDate));
    return duration
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateCardSize(); // Update grid styles on resize
  }

  updateCardSize(): void {
    const itemCount = this.deviceLastSeenList.length;
    const countGap = itemCount * 5;
    const screenWidth = window.innerWidth - countGap;
    const screenHeight = window.innerHeight;
    const columns = Math.ceil(Math.sqrt(itemCount * (screenWidth / screenHeight)));
    const rows = Math.ceil(itemCount / columns);
    const ss = itemCount * (screenWidth / screenHeight);
    if (rows > columns) {
      this.minWidth = columns;
    } else {
      this.minWidth = Math.floor(ss / columns)
    }

    this.cardSize = ((window.innerWidth - 60 - countGap) / itemCount) * columns;
    this.iconSize = Math.max(22, Math.min(70, this.cardSize * 0.13));
    this.mainSize = Math.max(16, Math.min(55, this.cardSize * 0.065));
    this.smallSize = Math.max(12, Math.min(45, this.cardSize * 0.04));
  }

  calculateCardSize(itemCount: number): number {
    const baseSize = 1670; // Base card size
    const minSize = 50; // Minimum card size for many items

    // Calculate size inversely proportional to the number of items
    return Math.max(baseSize - itemCount * 2, minSize);
  }

  async setupData(locationId) {
    if (locationId && this.clientId) {
      const locationDS = await this.firestoreService.getLocationByIdForClientId(locationId, this.clientId);
      this.locationName = locationDS.data().name;
      this.contextService.setLocationName(this.locationName)
    }

    this.allDevicesSubscription = this.firestoreService.getAllDevicesForLocationId(locationId).subscribe(deviceList => {
      this.deviceLastSeenList = deviceList.map((device) => {
        if (device.lastSeenTimestamp) {
          const durationLastSeen = this.convertLastSeenTimestamp(device.lastSeenTimestamp);
          let lastSeenColor = 'danger'; // Default: More than 24 hours
          if (durationLastSeen.asHours() <= 4) {
            lastSeenColor = 'success'; // Last seen within 4 hours
          } else if (durationLastSeen.asHours() > 4 && durationLastSeen.asHours() <= 24) {
            lastSeenColor = 'warning'; // Last seen between 4 and 24 hours
          }
          return {
            ...device,
            deviceLastSeen: `${durationLastSeen.days()}d ${durationLastSeen.hours()}h ${durationLastSeen.minutes()}m`,
            deviceSeenStatus: lastSeenColor
          };
        }
        return device;
      })
        .sort((a, b) => Number(a.deviceNumber) - Number(b.deviceNumber));
      this.updateCardSize();
      this.isDataLoaded = true;
    });
  }

  ngOnDestroy() {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.allDevicesSubscription?.unsubscribe();
  }
}
