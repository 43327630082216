import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormField} from '@angular/material/form-field';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../services/firestore.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ClientInContextService} from '../../services/client-in-context.service';
import {environment} from '../../../environments/environment';
import moment from 'moment';
import {TIME_ZONE} from '../utils/time-utils';
//import {PresenceService} from "../../presence.service";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  //public loggedInUserFromAuthServiceSubscription: Subscription;
  //public loggedInUserDocData: any;
  //clientSubscription: Subscription;
  //clientInContextDocData: any;
  //selectedClientId: string;
  //clientInContextServiceSubscription: Subscription;
  //selectedClientDocData: any;
  //clientLocationsSubscription: Subscription;
  //clientLocationsList: any[];
  //selectedClientLocationId = '-1';
  url: string;
  currentDate: any = new Date();
  private intervalId: any;
  weekNumber = 0;
  constructor(
    public authService: AuthService,
    public firestoreService: FirestoreService,
    public route: ActivatedRoute,
    private router: Router,
    public clientInContextService: ClientInContextService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //console.log(JSON.stringify(event));
        // Hide loading indicator
        const urlAfterRedirects = event.urlAfterRedirects;
        this.url = urlAfterRedirects.substring(1, urlAfterRedirects.length);
      }
    });
   /* this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
        if (this.loggedInUserDocData) {
          if (!this.clientInContextService.clientInContext && this.loggedInUserDocData.clients && (this.loggedInUserDocData.clients.length > 0)) {
            this.clientSubscription = firestoreService
              .getClientById(this.loggedInUserDocData.lastClientIdSelected
                ? this.loggedInUserDocData.lastClientIdSelected : this.loggedInUserDocData.clients[0].clientId)
              .subscribe((clientDocData) => {
                this.clientInContextDocData = clientDocData;
                this.selectedClientId = clientDocData.id;
                const clientElementInUserDoc = this.loggedInUserDocData?.clients.filter(client => client.clientId === clientDocData.id);
                if (clientElementInUserDoc && clientElementInUserDoc.length > 0) {
                  this.clientInContextDocData.role = clientElementInUserDoc[0]?.role;
                }
                this.clientInContextService.clientInContextSubject.next(this.clientInContextDocData);
                this.firestoreService.updateUserById(this.loggedInUserDocData.id, {lastClientIdSelected: clientDocData.id});
                this.clientSubscription?.unsubscribe();

                this.clientLocationsSubscription = firestoreService.getAllLocationsForClientId(this.selectedClientId).subscribe(locations => {
                  this.clientLocationsList = locations?.sort((locA: any, locB: any) => {
                    return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
                  });
                  if (this.clientLocationsList.length === 1) {
                    this.selectedClientLocationId = this.clientLocationsList[0].id;
                  } else {
                    const prevSelectedLocMap = this.loggedInUserDocData.prevSelectedLocMap;
                    this.selectedClientLocationId = prevSelectedLocMap && prevSelectedLocMap[this.selectedClientDocData.id] ?
                      prevSelectedLocMap[this.selectedClientDocData.id] : '-1';
                  }
                });
              });
          }
        }
      }
    );

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(clientInContext => {
      this.selectedClientDocData = clientInContext;
      if (!this.selectedClientDocData) {
        return;
      }
      this.clientLocationsSubscription = firestoreService.getAllLocationsForClientId(this.selectedClientDocData.id).subscribe(locations => {
        this.clientLocationsList = locations?.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        });
        if (this.clientLocationsList.length === 1) {
          this.selectedClientLocationId = this.clientLocationsList[0].id;
        } else {
          const prevSelectedLocMap = this.loggedInUserDocData.prevSelectedLocMap;
          this.selectedClientLocationId = prevSelectedLocMap && prevSelectedLocMap[this.selectedClientDocData.id] ?
            prevSelectedLocMap[this.selectedClientDocData.id] : '-1';
          if (this.selectedClientId === '-1') {
            this.clientInContextService.clientLocSubject.next(null);
          } else {
            this.clientInContextService.clientLocSubject.next(this.clientLocationsList.filter(loc => loc.id === this.selectedClientLocationId)[0]);
          }
        }
      });
    });*/
  }


  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.weekNumber = moment().isoWeek();
      this.currentDate = new Date();

      this.currentDate = moment.tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss');
    }, 1000);
  }

  ngOnDestroy(): void {
  }

  /*onClientChange(clientId) {
    this.clientSubscription = this.firestoreService
      .getClientById(clientId)
      .subscribe((clientDocData) => {
        this.clientInContextDocData = clientDocData;
        this.selectedClientId = clientDocData.id;
        const clientElementInUserDoc = this.loggedInUserDocData?.clients.filter(client => client.clientId === clientDocData.id);
        if (clientElementInUserDoc && clientElementInUserDoc.length > 0) {
          this.clientInContextDocData.role = clientElementInUserDoc[0]?.role;
        }
        this.clientInContextService.clientInContextSubject.next(this.clientInContextDocData);
        this.firestoreService.updateUserById(this.loggedInUserDocData.id, {lastClientIdSelected: clientDocData.id});
        this.clientSubscription?.unsubscribe();
        this.router.navigate(['/sign-in']);
      });
  }*/

  /*onClientLocationChange(locationId: string) {
    this.selectedClientLocationId = locationId;
    let prevSelectedMapToUpdate: any;
    if (this.loggedInUserDocData.prevSelectedLocMap) {
      prevSelectedMapToUpdate = this.loggedInUserDocData.prevSelectedLocMap;
    } else {
      prevSelectedMapToUpdate = {};
    }
    if (locationId !== '-1') {
      prevSelectedMapToUpdate[this.selectedClientDocData.id] = locationId;
    } else {
      delete prevSelectedMapToUpdate[this.selectedClientDocData.id];
    }
    this.firestoreService.updateUserById(this.loggedInUserDocData.id, {prevSelectedLocMap: prevSelectedMapToUpdate});
    this.clientInContextService.clientLocSubject.next(this.clientLocationsList.filter(loc => loc.id === locationId)[0]);
  }*/

  getInitials(nameString) {
    if (!nameString) {
      return '';
    }
    if (nameString.indexOf(' ') === -1) {
      return nameString.charAt(0).toUpperCase();
    } else {
      const fullName = nameString.split(' ');
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    }
  }

}
