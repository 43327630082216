import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {FirestoreService} from "./firestore.service";
import {ClientInContextService} from "./client-in-context.service";
import {AuthService} from "./auth.service";
import {findIndex} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UrlNavigationInterceptorService {
  private loggedInUserDocData: any;
  private navigationEvent: NavigationEnd;

  constructor(private router: Router,
              private firestoreService: FirestoreService,
              private clientInContextService: ClientInContextService,
              private authService: AuthService) {
    router.events.subscribe((event) => {

    });
  }
}
