import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from '../../services/firestore.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss'],
})
export class ClientDashboardComponent implements OnInit, OnDestroy {
  name = 'Angular';
  width = 700;
  height = 300;
  fitContainer = false;

  view: any[] = [(window.innerWidth / 2) - 60, (window.innerHeight / 2) - 60];
  
  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Sales';
  timeline = true;
  doughnut = true;
  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']
  };
  //pie
  showLabels = true;
  // data goes here
  public single = [
    {
      name: 'China',
      value: 2243772
    },
    {
      name: 'USA',
      value: 1126000
    },
    {
      name: 'Norway',
      value: 296215
    },
    {
      name: 'Japan',
      value: 257363
    },
    {
      name: 'Germany',
      value: 196750
    },
    {
      name: 'France',
      value: 204617
    }
  ];
  public multi = [
    {
      name: 'China',
      series: [
        {
          name: '2018',
          value: 2243772
        },
        {
          name: '2017',
          value: 1227770
        }
      ]
    },
    {
      name: 'USA',
      series: [
        {
          name: '2018',
          value: 1126000
        },
        {
          name: '2017',
          value: 764666
        }
      ]
    },
    {
      name: 'Norway',
      series: [
        {
          name: '2018',
          value: 296215
        },
        {
          name: '2017',
          value: 209122
        }
      ]
    },
    {
      name: 'Japan',
      series: [
        {
          name: '2018',
          value: 257363
        },
        {
          name: '2017',
          value: 205350
        }
      ]
    },
    {
      name: 'Germany',
      series: [
        {
          name: '2018',
          value: 196750
        },
        {
          name: '2017',
          value: 129246
        }
      ]
    },
    {
      name: 'France',
      series: [
        {
          name: '2018',
          value: 204617
        },
        {
          name: '2017',
          value: 149797
        }
      ]
    }
  ];

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private firestoreService: FirestoreService,
  ) {



  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
  }

  onSelect($event){

  }
}
