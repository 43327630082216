



<div class="app-container overflow-auto">
  <main class="main-content">
   
    <div class="row" style="display: flex; flex: 1 1 auto; gap: 1.5vw;">
      <section class="content">
        <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="single"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>
      </section>
      <section class="content" >
        <ngx-charts-bar-vertical
        [scheme]="colorScheme"
        [results]="single"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
      </section>
     
    </div>
    <!-- <div class="row" style="display: flex; flex: 1 1 auto; gap: 1.5vw;">
      <section class="content">
        <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="single"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>
      </section>
      <section class="content" >
        <ngx-charts-bar-vertical
        [scheme]="colorScheme"
        [results]="single"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
      </section>
     
    </div> -->

  </main>
</div>

