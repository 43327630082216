<mat-toolbar>
 <div class="d-flex align-items-center w-100 font-20">
  <h1><img width="170" height="" src="assets/Opus Watch_Logo_RGB_L.png"></h1>
  <div class="green-house f-grow text-center opacity-fade" >
    <span *ngIf="contextService.getLocationName() as locationName">{{locationName}}</span>
  </div>
  <div class="date_and_time opacity-fade"><span class="date">{{ currentDate | date: 'MMMM d, y' }}</span><span class="week-number">{{weekNumber}}</span><span class="time">{{
    currentDate | date: 'HH:mm' }}</span></div>
 </div>
</mat-toolbar>
