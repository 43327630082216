export const environment = {
  production: true,
  useEmulators: false,
  functionsBase: 'https://us-central1-opus-f644d.cloudfunctions.net/',
  firebase: {
    apiKey: "AIzaSyC21mFUZeOSFlv5KJxleHcFk0FtYvd3W7o",
    authDomain: "opus-f644d.firebaseapp.com",
    databaseURL: "https://opus-f644d.firebaseio.com",
    projectId: "opus-f644d",
    storageBucket: "opus-f644d.appspot.com",
    messagingSenderId: "597299589420",
    appId: "1:597299589420:web:9cf58a8a5b11afd62efc89",
    measurementId: "G-33Z99PFHF3"
  }
};
