import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireAuth, AngularFireAuthModule} from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import {USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/compat/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {USE_EMULATOR as USE_FIRESTORE_EMULATOR} from '@angular/fire/compat/firestore';
import {getFirestore, provideFirestore} from '@angular/fire/firestore'
import {USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';
import {MaterialModule} from './material.module';
import {MatTab, MatTabBody, MatTabGroup, MatTabHeader, MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from "@angular/material/divider";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SimplebarAngularModule} from 'simplebar-angular';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {NgsContenteditableModule} from "@ng-stack/contenteditable";
import {ClipboardModule} from 'ngx-clipboard';
import { GoogleMapsModule } from '@angular/google-maps';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
  NgxMatDateFormats, NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import {MatInputModule} from '@angular/material/input';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';

import {
  NavComponent,
  ClientDashboardComponent,
} from './components.module';
import { SafeHtmlPipe } from './common/pipes/safe-html.pipe';
import { YouTubePlayerModule } from "@angular/youtube-player";
import {MatDialogModule} from "@angular/material/dialog";
import {ContenteditableValueAccessorModule} from "@tinkoff/angular-contenteditable-accessor";
import {MatCardModule} from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { SidenavHelpComponent } from './common/sidenav-help/sidenav-help.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatRadioModule} from "@angular/material/radio";
import {CustomLoader} from "./common/i18n/custom-loader";
import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';
import {FirestoreService} from "./services/firestore.service";
import {provideFirebaseApp, initializeApp} from "@angular/fire/app";

export function initializeAppOld(afAuth: AngularFireAuth): () => Promise<null> {
  return () => {
    return new Promise((resolve) => {
      if (!environment.useEmulators) {
        return resolve(null);
      } else {
        afAuth.useEmulator(`http://${location.hostname}:9099/`).then(() => {
          resolve(null);
        });
      }
    });
  };
}

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export function FirestoreTranslationsLoaderFactory(firestoreService: FirestoreService) {
  return new CustomLoader(firestoreService);
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    ClientDashboardComponent,
    SafeHtmlPipe,
    SidenavHelpComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SimplebarAngularModule,
    MatTabsModule,
    NgxChartsModule,
    NgsContenteditableModule,
    ClipboardModule,
    MatDatepickerModule,
    MatDividerModule,
    MatDialogModule,
    YouTubePlayerModule,
    ContenteditableValueAccessorModule,
    NgOptimizedImage,
    GoogleMapsModule,
    NgxMatDatetimePickerModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    MtxTooltipModule,
    MatSidenavModule,
    MatRadioModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FirestoreTranslationsLoaderFactory,
        deps: [FirestoreService]
      }
    }),
    NgxMaskDirective
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AngularFireAuth],
      useFactory: initializeAppOld
    },
    {provide: REGION, useValue: 'europe-west1'},
    {provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined},
    {provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined},
    {provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
    provideNgxMask(),
    provideFirebaseApp( () => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
  ],

  bootstrap: [AppComponent],
})

export class AppModule {}
