import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FirestoreService} from '../../services/firestore.service';
import {ActivatedRoute} from '@angular/router';
import {ClientInContextService} from 'src/app/services/client-in-context.service';
import {Subscription} from 'rxjs';

const moment = require('moment-timezone');

@Component({
  selector: 'app-device-battery',
  templateUrl: './device-battery.component.html',
  styleUrl: './device-battery.component.scss'
})
export class DeviceBatteryComponent implements OnInit, OnDestroy {
  cards: any;
  totalRows: any;
  minWidth: number;
  cardSize: number;
  iconSize: number;
  mainSize: number;
  smallSize: number;
  deviceBatteryList: any[];
  isDataLoaded = true;
  locationName = '';
  clientId: string;
  loggedInUserFromAuthServiceSubscription: Subscription;
  allDevicesSubscription: Subscription;

  constructor(private renderer: Renderer2,
    private elRef: ElementRef,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private route: ActivatedRoute,
    private contextService: ClientInContextService) {
  }

  ngOnInit(): void {
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userInfo: any) => {
        this.clientId = userInfo.clientId;
        this.firestoreService.setClientId(userInfo.clientId);
        this.cards = Array.from({length: 32});
        const key = this.route.snapshot.queryParamMap.get('key');
        const locationId = this.route.snapshot.queryParamMap.get('location_id');
        this.getDevices(key, locationId);
      });
  }

  async getDevices(castingKey, locationId) {
    if (castingKey) {
      this.isDataLoaded = false;
    }

    if (this.authService.loggedInCustomUID && this.clientId) {
      await this.setupData(locationId);
      return;
    }

    try {
      await this.authService.signInAnonymously();
    } catch (error) {
      console.log(`Error:${JSON.stringify(error)}`);
    }

    console.log(`Getting sign-in token...`);
    this.firestoreService.getSignInToken(castingKey).subscribe(async (response) => {
      try {
        this.clientId = response.clientId;
        this.firestoreService.setClientId(response.clientId);
        await this.authService.signIn(response.customToken);
        console.log(`Signed in successfully`);
        await this.setupData(locationId)
        return;
      } catch (error) {
        console.log(`Error:${JSON.stringify(error)}`);
        this.isDataLoaded = true;
      }
    });
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateCardSize(); // Update grid styles on resize
  }

  updateCardSize(): void {
    const itemCount = this.deviceBatteryList.length;
    const countGap = itemCount * 5;
    const screenWidth = window.innerWidth - countGap;
    const screenHeight = window.innerHeight;
    const columns = Math.ceil(Math.sqrt(itemCount * (screenWidth / screenHeight)));
    const rows = Math.ceil(itemCount / columns);
    const ss = itemCount * (screenWidth / screenHeight);
    if (rows > columns) {
      this.minWidth = columns;
    } else {
      this.minWidth = Math.floor(ss / columns);
    }

    this.cardSize = ((window.innerWidth - 60 - countGap) / itemCount) * columns;
    this.iconSize = Math.max(22, Math.min(70, this.cardSize * 0.13));
    this.mainSize = Math.max(16, Math.min(55, this.cardSize * 0.065));
    this.smallSize = Math.max(12, Math.min(45, this.cardSize * 0.04));
  }

  async setupData(locationId) {
    if (locationId && this.clientId) {
      const locationDS = await this.firestoreService.getLocationByIdForClientId(locationId, this.clientId);
      this.locationName = locationDS.data().name;
      this.contextService.setLocationName(this.locationName)
    }
    this.allDevicesSubscription = this.firestoreService.getAllDevicesForLocationId(locationId).subscribe(deviceList => {
      this.deviceBatteryList = deviceList.map((device) => {
        if (device.hasOwnProperty('isCharging') && device.isCharging) {
          if (device.lastBatteryLeft) {
            device.batteryLeft = (device.lastBatteryPercentage + '%' || '');
          } else {
            device.batteryLeft = '';
          }
        } else {
          if (device.lastBatteryLeft) {
            device.batteryLeft = (device.lastBatteryPercentage + '%' || '') + ' (' + moment.duration(device.lastBatteryLeft * 1000).format('[d]d [h]h [m]m', {trim: false}) + ')';
          } else {
            device.batteryLeft = '';
          }
        }
        return device
      });

      this.updateCardSize();
      this.isDataLoaded = true;
    });
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.allDevicesSubscription?.unsubscribe();
  }
}
