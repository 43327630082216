import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormField} from '@angular/material/form-field';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../services/firestore.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ClientInContextService} from '../../services/client-in-context.service';
import {environment} from '../../../environments/environment';
import moment from 'moment';
import {TIME_ZONE} from '../utils/time-utils';
//import {PresenceService} from "../../presence.service";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  //public loggedInUserFromAuthServiceSubscription: Subscription;
  //public loggedInUserDocData: any;
  //clientSubscription: Subscription;
  //clientInContextDocData: any;
  //selectedClientId: string;
  //clientInContextServiceSubscription: Subscription;
  //selectedClientDocData: any;
  //clientLocationsSubscription: Subscription;
  //clientLocationsList: any[];
  //selectedClientLocationId = '-1';
  url: string;
  currentDate: any = new Date();
  private intervalId: any;
  weekNumber = 0;
  constructor(
    public authService: AuthService,
    public firestoreService: FirestoreService,
    public route: ActivatedRoute,
    private router: Router,
    public contextService: ClientInContextService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const urlAfterRedirects = event.urlAfterRedirects;
        this.url = urlAfterRedirects.substring(1, urlAfterRedirects.length);
      }
    });
  }


  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.weekNumber = moment().isoWeek();
      this.currentDate = new Date();

      this.currentDate = moment.tz(TIME_ZONE).format('YYYY-MM-DD HH:mm:ss');
    }, 1000);
  }

  ngOnDestroy(): void {
  }

}
