import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

import firebase from 'firebase/compat/app';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';

import {Observable, of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {FirestoreService} from "./firestore.service";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedInUserFromAuthService$: Observable<any>;
  isAnonymous: boolean;
  private loggedInUserFromAuthServiceSubscription: Subscription;
  private loggedInUserDocData: any;
  loggedInCustomUID: string = null;

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router,
              private firestoreService: FirestoreService,
              private route: ActivatedRoute) {
    this.loggedInUserFromAuthService$ = this.afAuth.authState.pipe(
        switchMap(async (user) => {
          if (user) {
            if (!user.isAnonymous) {
              this.loggedInCustomUID = user.uid;
            }
            this.firestoreService.setLoggedInUserUid(user.uid);
            this.isAnonymous = user.isAnonymous;
            const idTokenRes = await user.getIdTokenResult()/*.then((idTokenRes) => {*/;
            const claims = idTokenRes.claims;
              return {
                uid: user.uid,
                clientId: claims.clientId
              };

          } else {
            return of(null);
          }
        })
    );
    this.loggedInUserFromAuthServiceSubscription = this.loggedInUserFromAuthService$.subscribe(userDocData => {
      this.loggedInUserDocData = userDocData;
    });
  }

  // Users can have 3 roles - admin(type=client), manager(type=client), developer(type=developer)

  get isAuthenticated(): boolean {
    return this.loggedInUserFromAuthService$ !== null;
  }

  async signInAnonymously() {
    return this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      return this.afAuth.signInAnonymously();
    });
  }

  async signIn(token: string) {
    return this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      return this.afAuth.signInWithCustomToken(token);
    });
  }

  async resetPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email)
  }

  async signOut() {
    this.afAuth.signOut().then(() => {

      console.log('signed out');
      this.router.navigate(['']);
    });
  }

}
