<div class="app-container overflow-auto">
  <main class="main-content">
 
    <div class="batery-card-list">
      <div *ngIf="!isDataLoaded" class="loader-row" [ngStyle]="{
        'grid-template-columns': 'repeat('+minWidth+', 1fr)'
      }">
      <div class="load item" *ngFor="let i of [].constructor(40)"></div>
    </div>
      <div *ngIf="isDataLoaded" class="card-row" [ngStyle]="{
        'grid-template-columns': 'repeat('+minWidth+', 1fr)'
      }">
        <!-- Inner loop for the items in each row -->
        <div class="card-item" *ngFor="let item of deviceBatteryList" >
          <span [ngClass]="{'is-charging':item.isCharging}" class="icon "><mat-icon class="material-symbols-outlined" [ngStyle]="{'font-size': iconSize +'px', 'width':iconSize +'px', 'height':iconSize +'px'}">battery_charging_60</mat-icon></span>
         
          <span class="b-level d-flex flex-column justify-content-center">
            <span [ngStyle]="{'font-size': mainSize +'px'}">{{item.deviceNumber}}</span>
            <small [ngStyle]="{'font-size':smallSize +'px'}">{{item.batteryLeft}}</small>
          </span>
        </div>
      </div>
    </div>
  </main>
</div>