import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {FirestoreService} from "../../services/firestore.service";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute} from "@angular/router";

const moment = require("moment-timezone");

@Component({
  selector: 'app-device-last-seen',
  templateUrl: './device-last-seen.component.html',
  styleUrl: './device-last-seen.component.scss'
})
export class DeviceLastSeenComponent implements OnInit {
  cards: any
  totalRows: any;
  minWidth: number;
  cardSize: number;
  iconSize: number;
  mainSize: number;
  smallSize: number;
  deviceLastSeenList: any[];
  isDataLoaded = true;

  constructor(private renderer: Renderer2,
              private elRef: ElementRef,
              private authService: AuthService,
              private firestoreService: FirestoreService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.cards = Array.from({length: 32});
    const key = this.route.snapshot.queryParamMap.get('key');
    const locationId = this.route.snapshot.queryParamMap.get('locationId');
    this.getDevices(key, locationId);
  }

  async getDevices(castingKey, locationId) {
    if (castingKey) {
      this.isDataLoaded = false;
    }
    try {
      await this.authService.signInAnonymously();
    } catch (error) {
      console.log(`Error:${JSON.stringify(error)}`);
    }

    console.log(`Getting sign-in token...`);
    this.firestoreService.getSignInToken(castingKey).subscribe(async (response) => {
      try {
        this.firestoreService.setClientId(response.clientId);
        await this.authService.signIn(response.customToken);
        console.log(`Signed in successfully`);
        this.firestoreService.getAllDevicesForLocationId(locationId).subscribe(deviceList => {
          this.deviceLastSeenList = deviceList.map((device) => {
            if (device.lastSeenTimestamp) {
              return {
                ...device,
                deviceLastSeen: moment(device.lastSeenTimestamp.toDate()).format('[d]d [h]h [m]m', {trim: false}),
                deviceSeenStatus: this.getDeviceClass(device)
              };
            }
            return device;
          });
          this.updateCardSize();
          this.isDataLoaded = true;
        });
        return;
      } catch (error) {
        console.log(`Error:${JSON.stringify(error)}`);
        this.isDataLoaded = true;

      }

    });
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateCardSize(); // Update grid styles on resize
  }

  updateCardSize(): void {
    const itemCount = this.deviceLastSeenList.length;
    const countGap = itemCount * 5;
    const screenWidth = window.innerWidth - countGap;
    const screenHeight = window.innerHeight;
    const columns = Math.ceil(Math.sqrt(itemCount * (screenWidth / screenHeight)));
    const rows = Math.ceil(itemCount / columns);
    const ss = itemCount * (screenWidth / screenHeight);
    if (rows > columns) {
      this.minWidth = columns;
    } else {
      this.minWidth = Math.floor(ss / columns)
    }

    this.cardSize = ((window.innerWidth - 60 - countGap) / itemCount) * columns;
    this.iconSize = Math.max(22, Math.min(70, this.cardSize * 0.13));
    this.mainSize = Math.max(16, Math.min(55, this.cardSize * 0.065));
    this.smallSize = Math.max(12, Math.min(45, this.cardSize * 0.04));


  }

  calculateCardSize(itemCount: number): number {
    const baseSize = 1670; // Base card size
    const minSize = 50; // Minimum card size for many items

    // Calculate size inversely proportional to the number of items
    return Math.max(baseSize - itemCount * 2, minSize);
  }

  getDeviceClass(getItem:any){
    
    if(getItem.lastSeenTimestamp){
    if (moment(getItem.lastSeenTimestamp.toDate()).isBefore(moment().subtract(1, 'days'))) {
      return 'danger';
    }
    
    else if (moment(getItem.lastSeenTimestamp.toDate()).isBefore(moment().subtract(4, 'hours'))) {
      return 'warning';
    } 
    
    else {
      return 'success';
    }
    }
    
  }
}
