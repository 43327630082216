<div class="app-container overflow-auto">
  <main class="main-content">
    <span class="active-group-user f-grow justify-content-end" *ngIf="allWorkersList?.length > 0">
      <strong>Total Active </strong>
      {{getActiveWorkerLength}} of {{allWorkersList.length}}
    </span>
    <div class="batery-card-list" *ngIf="groupedBy === 'WORKERGROUP' || !groupedBy">
      <ng-container *ngFor="let groupName of getKeyArrayFromMap();let i=index;">
        <!-- <h2 class="group-name">{{groupName}}</h2> -->
        <div class="group-name d-flex" *ngIf="groupName !== 'NOGROUP'">
          <span class="f-grow">{{ groupName }}</span>
          <span class="active-group-user">
            <strong>Active </strong>
            {{getActiveGroupedWOrker(groupName)}} of {{groupedWorkersMap.get(groupName).length}}
          </span>
        </div>

        <div class="card-row" >
          <!-- Inner loop for the items in each row -->
          <div class="card-item" *ngFor="let item of groupedWorkersMap.get(groupName)">
            <span [class]="getClassForWorker(item)" class="icon is-charging"><mat-icon class="material-symbols-outlined">person</mat-icon></span>

            <span class="b-level d-flex flex-column justify-content-center">
              <span>{{item.name}}</span>
              <small style="font-size: 11px;">
                {{item.lastTaskOrActivityName === 'NOGROUP_TASK_ACTVTY' ? '&nbsp;' :
                item.lastTaskOrActivityName}}
              </small>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="batery-card-list" *ngIf="groupedBy === 'ACTIVITY'">
      <ng-container *ngFor="let groupName of getKeyArrayFromActvtyTaskMap();let i=index;">
        <h2 class="group-name" *ngIf="groupName !== 'NOGROUP_TASK_ACTVTY'">{{ groupName }}</h2>
      <h2 class="group-name" *ngIf="groupName === 'NOGROUP_TASK_ACTVTY'">No active activity</h2>
        <div class="card-row" >
          <!-- Inner loop for the items in each row -->
          <div class="card-item" *ngFor="let item of groupedByActvtyOrTaskWorkersMap.get(groupName)">
            <span [class]="getClassForWorker(item)" class="icon is-charging"><mat-icon class="material-symbols-outlined">person</mat-icon></span>

            <span class="b-level d-flex flex-column justify-content-center">
              <span>{{item.name}}</span>
              <small style="font-size: 11px;">
                {{item.lastTaskOrActivityName === 'NOGROUP_TASK_ACTVTY' ? '&nbsp;' :
                item.lastTaskOrActivityName}}
              </small>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="batery-card-list" *ngIf="groupedBy === 'ALPHABETICALLY'">
      <div class="card-row" >
        <div class="card-item" *ngFor="let item of allWorkersList">
          <span [class]="getClassForWorker(item)" class="icon is-charging"><mat-icon class="material-symbols-outlined">person</mat-icon></span>

          <span class="b-level d-flex flex-column justify-content-center">
            <span>{{item.name}}</span>
            <small style="font-size: 11px;">
              {{item.lastTaskOrActivityName === 'NOGROUP_TASK_ACTVTY' ? '&nbsp;' :
              item.lastTaskOrActivityName}}
            </small>
          </span>
        </div>
    </div>
    </div>
  </main>
</div>
