<div class="app-container overflow-auto">
  <main class="main-content">
 
    <div class="batery-card-list">

      <div *ngIf="!isDataLoaded" class="loader-row" [ngStyle]="{
        'grid-template-columns': 'repeat('+minWidth+', 1fr)'
      }">
      <div class="load item" *ngFor="let i of [].constructor(40)"></div>
    </div>
      <div *ngIf="isDataLoaded" class="card-row" [ngStyle]="{
        'grid-template-columns': 'repeat('+minWidth+', 1fr)'
      }">
      <!-- 'grid-template-columns': 'repeat(auto-fit, minmax(' + minWidth + 'px, 1fr))' -->
        <div class="card-item " *ngFor="let item of deviceLastSeenList;let i = index">
          <span  class="icon is-charging {{item.deviceSeenStatus}}">
            <mat-icon *ngIf="item.deviceSeenStatus === 'success' || !item.deviceSeenStatus" class="material-symbols-outlined" [ngStyle]="{'font-size': iconSize +'px', 'width':iconSize +'px', 'height':iconSize +'px'}">visibility</mat-icon>
            <mat-icon *ngIf="item.deviceSeenStatus === 'danger' || item.deviceSeenStatus === 'warning'" class="material-symbols-outlined" [ngStyle]="{'font-size': iconSize +'px', 'width':iconSize +'px', 'height':iconSize +'px'}">visibility_off</mat-icon>
          </span>
         
          <span class="b-level d-flex flex-column justify-content-center">
            <span [ngStyle]="{'font-size': mainSize +'px'}">{{item.deviceNumber}}</span>
            <small [ngStyle]="{'font-size':smallSize +'px'}">{{item.deviceLastSeen}}</small>
          </span>
        </div>
      </div>
    </div>
  </main>
</div>