import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {FirestoreService} from "../../services/firestore.service";
import {ActivatedRoute} from "@angular/router";

const moment = require("moment-timezone");

@Component({
  selector: 'app-device-battery',
  templateUrl: './device-battery.component.html',
  styleUrl: './device-battery.component.scss'
})
export class DeviceBatteryComponent implements OnInit {
  cards: any;
  totalRows: any;
  minWidth: number;
  cardSize: number;
  iconSize: number;
  mainSize: number;
  smallSize: number;
  deviceBatteryList: any[];
  isDataLoaded = true;

  constructor(private renderer: Renderer2,
              private elRef: ElementRef,
              private authService: AuthService,
              private firestoreService: FirestoreService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.cards = Array.from({length: 32});
    const key = this.route.snapshot.queryParamMap.get('key');
    const locationId = this.route.snapshot.queryParamMap.get('locationId');
    this.getDevices(key, locationId);
  }

  async getDevices(castingKey, locationId) {
    if (castingKey) {
      this.isDataLoaded = false;
    }
    try {
      await this.authService.signInAnonymously();
    } catch (error) {
      console.log(`Error:${JSON.stringify(error)}`);
    }

    console.log(`Getting sign-in token...`);
    this.firestoreService.getSignInToken(castingKey).subscribe(async (response) => {
      try {
        this.firestoreService.setClientId(response.clientId);
        await this.authService.signIn(response.customToken);
        console.log(`Signed in successfully`);
        this.firestoreService.getAllDevicesForLocationId(locationId).subscribe(deviceList => {
          //console.log('deviceList[0]:' + JSON.stringify(deviceList[0]));

          // const updateDeviceList = deviceList.map((device) => {
          //   if (device.lastSeenTimestamp) {
          //     return {
          //       ...device,
          //       deviceLastSeen: moment(device.lastSeenTimestamp.toDate()).format('[d]d [h]h [m]m', { trim: false }),
          //     };
          //   }
          //   return device;
          // })


          this.deviceBatteryList = deviceList.map((device) => {
            if (device.hasOwnProperty('isCharging') && device.isCharging) {
              if (device.lastBatteryLeft) {
                device.batteryLeft = (device.lastBatteryPercentage + '%' || '');
              } else {
                device.batteryLeft = '';
              }
            } else {
              if (device.lastBatteryLeft) {
                device.batteryLeft = (device.lastBatteryPercentage + '%' || '') + ' (' + moment.duration(device.lastBatteryLeft * 1000).format('[d]d [h]h [m]m', {trim: false}) + ')';
              } else {
                device.batteryLeft = '';
              }
            }
            return device
          });
          
          this.updateCardSize();
          this.isDataLoaded = true;
        });
        return;
      } catch (error) {
        console.log(`Error:${JSON.stringify(error)}`);
        this.isDataLoaded = true;
      }
    });
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateCardSize(); // Update grid styles on resize
  }

  updateCardSize(): void {
    const itemCount = this.deviceBatteryList.length;
    const countGap = itemCount * 5;
    const screenWidth = window.innerWidth - countGap;
    const screenHeight = window.innerHeight;
    const columns = Math.ceil(Math.sqrt(itemCount * (screenWidth / screenHeight)));
    const rows = Math.ceil(itemCount / columns);
    const ss = itemCount * (screenWidth / screenHeight);
    if (rows > columns) {
      this.minWidth = columns;
    } else {
      this.minWidth = Math.floor(ss / columns);
    }

    this.cardSize = ((window.innerWidth - 60 - countGap) / itemCount) * columns;
    this.iconSize = Math.max(22, Math.min(70, this.cardSize * 0.13));
    this.mainSize = Math.max(16, Math.min(55, this.cardSize * 0.065));
    this.smallSize = Math.max(12, Math.min(45, this.cardSize * 0.04));
  }
}
