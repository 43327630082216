import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './common/guards/auth.guard';

import {
  ClientDashboardComponent,
} from './components.module';
import { DeviceLastSeenComponent } from './components/device-last-seen/device-last-seen.component';
import { DeviceBatteryComponent } from './components/device-battery/device-battery.component';
import { PresencesComponent } from './components/presences/presences.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: ClientDashboardComponent
  // },
  {
    path: '',
    redirectTo: 'devices_last_seen',
    pathMatch: 'full'
  },
  {
    path: 'devices_last_seen',
    component: DeviceLastSeenComponent
  },
  {
    path: 'devices_battery',
    component: DeviceBatteryComponent
  },
  {
    path: 'presences',
    component: PresencesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
