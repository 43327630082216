import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './common/guards/auth.guard';

import {
  ClientDashboardComponent,
} from './components.module';

const routes: Routes = [
  {
    path: '',
    component: ClientDashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
